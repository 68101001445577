<template>
  <div>
    <base-table ref="tableRef"
                :columnsData="columns"
                rowKey="id"
                :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">角色管理</h4>
          <a-button class="ml-3"
                    type="primary"
                    @click="handlerEdit('add')"
                    html-type="submit">新增</a-button>
        </div>
      </template>

      <template #status="{text}">
        <a-tag :color="text===1? 'green' : 'red' ">{{text==1? '正常' : '停用'}}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button type="link" class="p-0"
                  @click="handlerEdit('edit',record)">编辑</a-button>
        <a-button type="link" class="p-0 ml-4"
                  @click="handlerDelete(record)">删除</a-button>
      </template>
    </base-table>

    <add-modal v-if="isEidtShow"
               :show.sync="isEidtShow"
               :id="activeId"
               :type="type" :typeList="typeList" />
  </div>
</template>

<script>
import {
  getList,
  updateStatus
} from "@/api/system/role.js"
import addModal from './components/add-modal.vue'

export default {
  components: { addModal },
  data () {
    return {
      columns: [
        {
          title: "角色ID",
          dataIndex: "id",
          align: "center",
        },
        {
          title: "角色类型",
          dataIndex: "type",
          align: "center",
          slots: {
            customRender: 'type'
          },
          slotsType: 'format',
          slotsFunc: (val) => this.formatType(val)
        },
        {
          title: "角色名",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          align: "center",
        },
      ],

      type: "add",
      activeId: '',
      tableData: [],
      isEidtShow: false,
      // 1-普通，2-网点，3-财务，4-区域
      typeList: {
        1: '普通',
        2: '网点',
        3: '财务',
        4: '区域'
      }
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    formatType (id) {
      return this.typeList[id]
    },
    async initData () {
      const { data, code } = await getList()
      if (code === 0) {
        this.tableData = data.list
      }
    },

    handlerEdit (type, row) {
      this.type = type
      this.activeId = row ? row.id : ''
      this.isEidtShow = true
    },
    handlerDelete(row){
      updateStatus({
        id: row.id,
        status: -1,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success("已删除")
        }
      })
    }
  },
}
</script>

<style>
</style>