<template>
  <div>
    <!-- 新增 编辑modal -->
    <a-modal :visible="show"
             width="600px"
             :title="type=='add'?'新增': '编辑'"
             @ok="handlerSave"
             @cancel="$emit('update:show', false)">
      <a-form-model ref="addForm"
                    :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 18 }"
                    :model="formData">
        <a-form-model-item prop="name"
                           label="角色类型">
          <a-select v-model="formData.type"
                    :disabled="type!='add'"
                    style="width: 100%"
                    placeholder="请选择角色类型">
            <a-select-option :value="Number(item)"
                             :key="item"
                             v-for="item in Object.keys(typeList)">{{typeList[item]}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="name"
                           label="角色名称">
          <a-input allowClear
                   v-model="formData.name"
                   placeholder="请输入角色名称"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="name"
                           label="菜单权限">
          <a-tree v-model="menus"
                  checkable
                  checkStrictly
                  @check="handleCheckMenu"
                  style="height:400px;overflow: auto;"
                  :replaceFields="{children:'submenu_list', title:'name', key:'id' }"
                  :selected-keys="formData.menu_ids"
                  :tree-data="treeData" />
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getDetail,
  getMenuList,
  add,
  update
} from "@/api/system/role.js"
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    typeList: {
      type: Object,
      default: () => { }
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      treeData: [],
      menus: {
        checked: [],
        halfChecked: []
      },
      formData: {
        name: "",
        type: undefined,
        menu_ids: []
      },
    }
  },
  mounted () {
    this.getMenuList()
    if (this.type === 'edit')
      this.initData()
  },
  methods: {
    async getMenuList () {
      const { data } = await getMenuList()
      this.treeData = data.list.map(el => {
        if (el.submenu_list.length > 0) {
          el.submenu_list.map(item => {
            item.pId = el.id
            if (item.submenu_list.length > 0) {
              item.submenu_list.map(a => {
                a.pId = item.id
                return a
              })
            }
            return item
          })
        }
        return el
      })
      console.log(this.treeData)
    },
    async initData () {
      const { data } = await getDetail({ id: this.id })
      this.formData = data
      this.formData.id = this.id
      this.formData.menu_ids = data.menu_ids.split(',').map(el => Number(el))
      // this.formData.menus =  this.formData.menu_ids
      this.menus.checked = this.formData.menu_ids
    },

    handleCheckMenu (checkedKeys, e) {
      this.formData.menus = checkedKeys
      // const checkedKeysResult = [...checkedKeys, ...e.halfCheckedKeys]
      // this.formData.menus = checkedKeysResult
      const arr = this.treeData.filter(el => el.id === e.node.eventKey)

      // 如果选中的是父节点，则手动去把父节点下所有的子节点选中
      if (e.checked) {
        if (arr.length) {
          if (arr[0].submenu_list && arr[0].submenu_list.length > 0) {
            arr[0].submenu_list.map(item => {
              this.menus.checked.push(item.id)
              // 如果还有一级 则也要勾选上
              if(item.submenu_list && item.submenu_list.length>0){
                item.submenu_list.map(i=>{
                  this.menus.checked.push(i.id)
                })
              }
            })
          }
        } else {
          if (e.node.$parent.dataRef) {
            this.menus.checked.push(e.node.$parent.dataRef.pId)
          }
          this.menus.checked.push(e.node.dataRef.pId)
          if(e.node.dataRef.submenu_list && e.node.dataRef.submenu_list.length>0){
            e.node.dataRef.submenu_list.map(el=>{
              this.menus.checked.push(el.id)
            })
          }
        }
      } else {
        if (arr.length) {
          if (arr[0].submenu_list && arr[0].submenu_list.length > 0) {
            const childrenArr = []
            arr[0].submenu_list.map(item => {
              childrenArr.push(item.id)
              if (item.submenu_list && item.submenu_list.length > 0) {
                item.submenu_list.map(el => {
                  childrenArr.push(el.id)
                })
              }
            })
            this.menus.checked = this.menus.checked.filter(el => !childrenArr.includes(el))
          }
        } else {
          if(!e.node.dataRef.submenu_list){
            this.menus.checked = this.menus.checked.filter(el => el!=e.node.eventKey)
          }else{
            const cArr = e.node.dataRef.submenu_list.map(el=>el.id)
            this.menus.checked = this.menus.checked.filter(el => !cArr.includes(el))
          }
          if (e.node.$parent.dataRef) {
            const childArr = e.node.$parent.dataRef.submenu_list.map(el=>el.id)
            let flag = true

            childArr.map(el=>{
              if(this.menus.checked.includes(el)){
                flag = false
              } 
            })
            if(flag){
              this.menus.checked = this.menus.checked.filter(el => e.node.$parent.dataRef.pId != el)
              this.menus.checked = this.menus.checked.filter(el => e.node.dataRef.pId != el)
            }
          }
        }
      }
    },

    handlerSave () {
      const params = JSON.parse(JSON.stringify(this.formData))  
      params.menu_ids = this.menus.checked.join(',')
      if (this.type === "add") {
        add(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      } else {
        update(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改成功！")
            this.$emit('update:show', false)
            this.$parent.initData()
          }
        })
      }
    },
  }
}
</script>

<style>
</style>